import "slick-carousel";

import "slick-carousel/slick/slick.css";

$(document).ready(function() {
  $("#homeSlider").slick({
    dots: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: $("#homeDots"),
    autoplay: true
  });
});
